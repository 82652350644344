import axios from 'axios'
import axiosRetry from 'axios-retry'
import { store } from '../../redux/store'
import { logoutAction } from '../../redux/session/authentication/actions'

export const backendClient = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api`
})

// Enable retry for axios
axiosRetry(backendClient, {
  retries: 0, // Number of retries
  retryDelay: retryCount => {
    return retryCount * 1000 // Time between retries (1000ms per retry)
  },
  retryCondition: error => {
    // Retry on network errors or 5xx status codes
    return axiosRetry.isNetworkError(error) || axiosRetry.isRetryableError(error)
  }
})

// Add a request interceptor for auth token
backendClient.interceptors.request.use(
  config => {
    const token = (store.getState() as any)?.session?.authentication?.token
    // Do something before request is sent
    return {
      ...config,
      headers: {
        ...config.headers,
        'X-Token': token
      }
    }
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor to handle 401 errors
backendClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      // Token expired or invalid, trigger logout
      store.dispatch(logoutAction())
      window.location.href = '/' // Redirect to login page
    }
    return Promise.reject(error)
  }
)
